
<template>
  <div id="page-user-rdit">
    <div v-if="user_not_found">
      <b-alert show variant="danger">
        <h5 class="alert-heading"><i class="mdi mdi-block-helper mr-2"></i> Record Not Found!</h5>
        <hr class="my-1">
        <p class="mb-0">
          Sorry something went wrong. May be server issues. Refresh!
        </p>
      </b-alert>
    </div>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-body text-center">
                <img :src="$store.state.domain+'/'+shownUser.image" class="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />

                <h4 class="mt-3 mb-0" v-if="shownUser.role == 'buyer'">{{shownUser.first_name}} {{shownUser.last_name}}</h4>
                <h4 class="mt-3 mb-0" v-if="shownUser.role == 'worker'">{{shownUser.username}}</h4>
                <p class="text-muted">Type: {{shownUser.role}}</p>
                <button type="button" class="btn mr-1 btn-success btn-xs waves-effect mb-2 waves-light">
                  {{shownUser.status}}
                </button>
                <span>
                <button type="button" class="btn btn-xs waves-effect mb-2 waves-light" :class="shownUser.is_verified ? 'btn-info' : 'btn-danger'">
                  {{shownUser.is_verified ? 'Verified' : 'Not Verified'}}
                </button>
                </span>
                <div>
                  <router-link class="btn btn-outline-dark mr-2" :to="`/marketing?user_id=${shownUser.id}`"> Mail <i class="mdi mdi-email-edit-outline"></i></router-link>
                </div>
              </div>
            </div>
        </div>
        <!-- end col-->
        <div class="col-lg-8 col-xl-8">
            <div class="card">
                <div class="card-body">
                    <b-tabs content-class="mt-1" pills class="navtab-bg">
                        <b-tab active>
                            <template v-slot:title>
                              <i class="mdi mdi-account-check mr-1"></i> Account
                            </template>
                            <user-edit-tab-account />
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <i class="mdi mdi-account-cog mr-1"></i> Settings
                          </template>
                           <user-edit-tab-information />
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <i class="mdi mdi-lock-check mr-1"></i> Password
                          </template>
                          <user-edit-tab-password />
                        </b-tab>
                    </b-tabs>
                </div>
                <!-- end card-box-->
            </div>
        </div>
        <!-- end col -->
    </div>
    </div>
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
  },
  data() {
    return {
      isLoading: true,
      user_not_found: false,
    }
  },
  watch: {
    "$route":"fetchUser"
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    shownUser(){
      return this.$store.state.userList.user
    },
    earning(){
      return this.shownUser.earning
    },
    fetchUser(){
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
      .then((response) => {
        if(response.data.success){
          this.isLoading = false
        }else{
          this.alertSuccess(response.data.error)
          this.user_not_found = true
        }
      }).catch(err => { console.error(err); this.user_not_found = true });
    }
  },
  created() {
    if(!this.$store.state.userList.roles.length){
      this.$store.dispatch("userList/fetchRoles").catch(err => { console.error(err) })
    }
    this.fetchUser();
  }
}

</script>
