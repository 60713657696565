
<template>
  <div id="user-edit-tab-info">
    <h5 class="mb-3 text-uppercase bg-light p-2">
          <i class="mdi mdi-account-circle mr-1"></i> Personal Info
      </h5>
      <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3">
                <label class="d-block">Date of Birth</label>
                <date-picker v-model="data.dob" :editable="false" :first-day-of-week="1" placeholder="choose date" format="YYYY-MM-DD" lang="en"></date-picker>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group mb-3">
              <label>Country</label>
              <multiselect :options="countries.map(c => c.id)" :custom-label="parseCountry" v-model="data.country_id" placeholder="--select--"></multiselect>
            </div>
          </div>
      </div>
      <!-- end row -->

      <div class="row">
          <div class="col-12">
              <div class="form-group">
                  <label for="userbio">About</label>
                  <textarea id="userbio"  v-model="data.about" class="form-control" rows="3" placeholder="Write something..." v-validate="'max:1000'" name="about"></textarea>
                  <span class="text-danger font-sm"  v-show="errors.has('about')">{{ errors.first('about') }}</span>
              </div>
          </div>
          <!-- end col -->
      </div>
      <!-- end row -->
      <!-- end row -->
      <h5 class="mb-3 text-uppercase bg-light p-2">
          <i class="mdi mdi-earth mr-1"></i> Social
      </h5>
      <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                  <label for="social-fb">Facebook</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">
                              <i class="fab fa-facebook-square"></i>
                          </span>
                      </div>
                      <input v-model="data.facebook" id="social-fb" type="text" class="form-control" placeholder="Url" v-validate="'url:require_protocol'" name="facebook" />
                  </div>
                  <span class="text-danger font-sm"  v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>
              </div>
          </div>
          <div class="col-md-6">
              <div class="form-group">
                  <label for="social-tw">Twitter</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">
                              <i class="fab fa-twitter"></i>
                          </span>
                      </div>
                      <input  v-model="data.twitter" id="social-tw" type="text" class="form-control" placeholder="url" v-validate="'url:require_protocol'" name="twitter"  />
                  </div>
                  <span class="text-danger font-sm"  v-show="errors.has('twitter')">{{ errors.first('twitter') }}</span>
              </div>
          </div>
          <!-- end col -->
      </div>
      <!-- end row -->

      <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                  <label for="social-insta">Instagram</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">
                              <i class="fab fa-instagram"></i>
                          </span>
                      </div>
                      <input v-model="data.instagram" id="social-insta" type="text" class="form-control" placeholder="Url" v-validate="'url:require_protocol'" name="instagram"/>
                  </div>
                  <span class="text-danger font-sm"  v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>
              </div>
          </div>
          <div class="col-md-6">
              <div class="form-group">
                  <label for="social-gh">Github</label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">
                              <i class="fab fa-github"></i>
                          </span>
                      </div>
                      <input  v-model="data.github" id="social-gh" type="text" class="form-control" placeholder="url" v-validate="'url:require_protocol'" name="github" />
                  </div>
                  <span class="text-danger"  v-show="errors.has('github')">{{ errors.first('github') }}</span>
              </div>
          </div>
          <!-- end col -->
      </div>
      <div class="row justify-content-end">
        <div class="col-md-6">
          <div class="text-right">
            <button type="button" :disabled="!validateForm" @click.prevent="updateUser" class="btn btn-success mt-2">
                <i class="mdi mdi-content-save"></i> Update
            </button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'


export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      data:{},
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
    countries(){
      return this.$store.state.countries;
    },
    user(){
        return this.$store.state.userList.user
    }
  },
  methods: {
    parseCountry(id){
      let con = this.countries.find(item => item.id == id);
      if(con){ return con.name; }
    },
    updateUser(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.requestProcessor(formData, `${this.user.id}/update-info`)
      });
    },
    requestProcessor(formData, url){
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/users/${url}`,formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.alertSuccess(response.data.success)
              this.$store.commit('userList/SET_USER',response.data.user)
              this.patchUser();
            }else if(response.data.errors){
              response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
              this.alertError(response.data.error)
            }
        }).catch((error)=>{console.log(error)  })
    },
    patchUser(){
      this.data = {
        about: this.user.about,
        dob: this.user.dob ? new Date(this.user.dob) : null,
        country_id: this.user.country_id,
        twitter: this.user.twitter,
        facebook: this.user.facebook,
        instagram: this.user.instagram,
        github: this.user.github,
      }
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
